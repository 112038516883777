import cmd, { BaseResponse } from '../../cmd';
import { FavoritesList } from '@webtv/typings/cmd';

export const saveFavoritesList = (favoritesList: FavoritesList): Promise<BaseResponse<boolean>> => {
  const { id, name, sids } = favoritesList;
  const params = { favListName: name, favoriteList: id, channelList: sids };

  return cmd<boolean>({ cmd: 'saveCustomerFavList', params });
};

export default saveFavoritesList;

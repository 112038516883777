import React from 'react';

import useLocalStorage from '@webtv/hooks/use-local-storage';

import getFavoritesLists from '@webtv/utils/cmd/actions/get-favorites-lists';
import saveFavoritesList from '@webtv/utils/cmd/actions/save-favorites-list';
import deleteFavoritesList from '@webtv/utils/cmd/actions/delete-favorites-list';

import { FavoritesList } from '@webtv/typings/cmd';

import trackFavoritesList, {
  TrackFavoritesListAction
} from '@webtv/utils/google-analytics/events/track-favorites-list';

export const DEFAULT_FAVORITES_LIST = {
  id: -2,
  name: 'Alle kanaler i mit abonnement',
  sids: []
};

export interface FavoritesListsContextProps {
  children: React.ReactNode | React.ReactNode[];
}

export interface FavoritesListsContextValues {
  favoritLists?: FavoritesList[];
  onDeleteFavoritesList: (favoritesList: FavoritesList) => void;
  onSaveFavoritesList: (favoritesList: FavoritesList) => void;
  onSelectFavoritesList: (favoritesList: FavoritesList) => void;
  selectedFavoritesList?: FavoritesList;
}

export const FavoritesListsContext = React.createContext<FavoritesListsContextValues>(
  {} as FavoritesListsContextValues
);

export const FavoritesListsConsumer = FavoritesListsContext.Consumer;

export const FavoritesListsProvider = (props: FavoritesListsContextProps): JSX.Element => {
  const { children } = props;

  const [favoritLists, setFavoritLists] = React.useState<FavoritesList[]>();

  const [storedFavoritesList, setStoredFavoritesList, removeStoredFavoritesList] = useLocalStorage<
    FavoritesList
  >('favorites-list');

  const [selectedFavoritesList, setSelectedFavoritesList] = React.useState<FavoritesList>();

  const handleGetFavoritesLists = React.useCallback(async () => {
    const { data: favoritLists } = await getFavoritesLists();

    setFavoritLists(favoritLists);
  }, []);

  const handleSelectFavoritesList = React.useCallback(
    (favoritesList: FavoritesList) => {
      setStoredFavoritesList(favoritesList);
    },
    [setStoredFavoritesList]
  );

  const handleDeleteFavoritesList = React.useCallback(
    async (favoritesList: FavoritesList) => {
      const { id } = favoritesList;

      await deleteFavoritesList(id);

      await handleGetFavoritesLists();

      if (id === selectedFavoritesList?.id) {
        setSelectedFavoritesList(DEFAULT_FAVORITES_LIST);
      }

      trackFavoritesList(TrackFavoritesListAction.Deleted);
    },
    [handleGetFavoritesLists, selectedFavoritesList?.id]
  );

  const handleSaveFavoritesList = React.useCallback(
    async (favoritesList: FavoritesList) => {
      await saveFavoritesList(favoritesList);

      await handleGetFavoritesLists();

      setStoredFavoritesList(favoritesList);
    },
    [handleGetFavoritesLists, setStoredFavoritesList]
  );

  // GET FAVORIT LISTS - Get the favorit lists on first render
  React.useEffect(() => {
    handleGetFavoritesLists();
  }, [handleGetFavoritesLists]);

  // SELECTED FAVORIT LIST - set the selected favorit list, when the lists are fetch or each time the stored favorit list changes
  React.useEffect(() => {
    if (favoritLists) {
      const selectedFavoritList = favoritLists.find(
        ({ id, sids }) => id === storedFavoritesList?.id && sids.length
      );

      if (selectedFavoritList) {
        return setSelectedFavoritesList(selectedFavoritList);
      }

      removeStoredFavoritesList();

      setSelectedFavoritesList(DEFAULT_FAVORITES_LIST);
    }
  }, [removeStoredFavoritesList, favoritLists, storedFavoritesList]);

  return (
    <FavoritesListsContext.Provider
      value={{
        favoritLists,
        selectedFavoritesList,
        onSaveFavoritesList: handleSaveFavoritesList,
        onDeleteFavoritesList: handleDeleteFavoritesList,
        onSelectFavoritesList: handleSelectFavoritesList
      }}
    >
      {children}
    </FavoritesListsContext.Provider>
  );
};

export default FavoritesListsContext;

import React from 'react';

import useCookie from '@webtv/hooks/use-cookie';

const ACCEPTED_COOKIES_DAYS = 730;
const REJECTED_COOKIES_DAYS = 7;

export interface CookieConsentContextProps {
  children: React.ReactNode | React.ReactNode[];
}

export interface CookieConsentContextValues {
  cookiesAccepted?: boolean;
  handleAcceptCookies: () => void;
  handleRejectCookies: () => void;
}

export const CookieConsentContext = React.createContext<CookieConsentContextValues>(
  {} as CookieConsentContextValues
);

export const CookieConsentConsumer = CookieConsentContext.Consumer;

export const CookieConsentProvider = (props: CookieConsentContextProps): JSX.Element => {
  const { children } = props;

  const [cookiesAccepted, setCookiesAccepted] = useCookie('cookies-accepted');

  const handleAcceptCookies = React.useCallback(() => {
    setCookiesAccepted('true', { days: ACCEPTED_COOKIES_DAYS });
  }, [setCookiesAccepted]);

  const handleRejectCookies = React.useCallback(() => {
    setCookiesAccepted('false', { days: REJECTED_COOKIES_DAYS });
  }, [setCookiesAccepted]);

  React.useEffect(() => {
    if (cookiesAccepted === 'true') {
      handleAcceptCookies();
    }
  }, [cookiesAccepted, handleAcceptCookies]);

  return (
    <CookieConsentContext.Provider
      value={{
        handleAcceptCookies,
        handleRejectCookies,
        cookiesAccepted: cookiesAccepted ? Boolean(cookiesAccepted) : undefined
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};

export default CookieConsentContext;

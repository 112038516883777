import { logError } from '@webtv/utils/cmd/actions/_utils';

export const setLocalStorage = <T>(key: string, value: T): void => {
  if (typeof window === 'undefined') {
    console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
    return;
  }

  try {
    localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : `${value}`);
  } catch (error: any) {
    logError(`error setting localStorage key “${key}”`, error);
  }
};

export default setLocalStorage;

import { up, down, between, only } from '@webtv/utils/breakpoints';

import { Brand } from '@webtv/typings/shared';
import { Theme } from '@webtv/typings/theme';

export const norlysTheme: Theme = {
  name: Brand.Norlys,
  breakpoints: {
    values: {
      xs: 0,
      sm: 960,
      md: 1260,
      lg: 1320,
      xl: 1920
    },
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    up,
    down,
    between,
    only
  },
  components: {
    button: {
      borderRadius: '24px'
    }
  },
  palette: {
    action: {
      disabledText: '#c3c3c3',
      disabledBackground: '#505050'
    },
    background: {
      default: '#252525',
      level1: '#303030',
      level2: '#ccc',
      paper: '#252525'
    },
    common: {
      white: '#fff',
      black: '#000'
    },
    default: {
      main: '#505050',
      dark: '#474747',
      contrastText: '#fff'
    },
    divider: '#9e9e9e',
    error: {
      contrastText: '#fff',
      dark: '#d32f2f',
      main: '#f44336'
    },
    grey: {
      light: '#9b9b9b',
      main: '#505050',
      dark: '#303030'
    },
    primary: {
      main: '#f8232d',
      dark: '#bd2725',
      contrastText: '#fff'
    },
    secondary: {
      main: '#efefef',
      contrastText: '#000'
    },
    success: {
      main: '#25D287',
      light: '#eaf7b4'
    },
    text: {
      primary: '#fff',
      primaryHover: '#fff',
      secondary: '#9B9B9B',
      secondaryHover: '#fff'
    },
    warning: {
      main: '#ffa500',
      contrastText: '#fff'
    }
  },
  shape: {
    borderRadius: {
      sm: 4,
      md: 8,
      lg: 12
    }
  },
  spacing: (value: number | string) => (typeof value === 'string' ? value : `${value * 4}px`),
  typography: {
    fontFamily: `${'Open Sans'}, sans-serif`,
    spacing: {
      default: 0.7
    },
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700
    },
    size: {
      sm: '12px',
      md: '14px',
      lg: '16px'
    },
    h1: {
      fontFamily: `${'Open Sans'}, sans-serif`,
      fontSize: '22px',
      lineHeight: 1.5,
      fontWeight: 200
    },
    h2: {
      fontFamily: `${'Open Sans'}, sans-serif`,
      fontSize: '18px',
      lineHeight: 1.5,
      fontWeight: 200
    },
    h3: {
      fontFamily: `${'Open Sans'}, sans-serif`,
      fontSize: '16px',
      fontWeight: 200,
      lineHeight: 1.5
    },
    body: {
      fontFamily: `${'Open Sans'}, sans-serif`,
      fontSize: '14px',
      fontWeight: 200,
      lineHeight: 1.5,
      letterSpacing: 0.7
    },
    caption: {
      fontFamily: `${'Open Sans'}, sans-serif`,
      fontSize: '12px',
      fontWeight: 200,
      lineHeight: 1.5,
      letterSpacing: 0.7
    },
    button: {
      fontFamily: `${'Open Sans'}, sans-serif`,
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'none'
    }
  },
  zIndex: {
    playerControls: 1250,
    dialog: 1300,
    tooltip: 1500
  }
};

export default norlysTheme;

import { logError } from '@webtv/utils/cmd/actions/_utils';

const parseItem = (item: string) => {
  try {
    return JSON.parse(item);
  } catch {
    return item;
  }
};

export const getLocalStorage = <T>(key: string): T | null => {
  if (typeof window === 'undefined') return null;

  try {
    const item = localStorage.getItem(key);

    if (!item) return null;

    return parseItem(item);
  } catch (error: any) {
    logError(`error reading localStorage key “${key}”:`, error);
    return null;
  }
};

export default getLocalStorage;

import 'reset-css';
import 'regenerator-runtime/runtime';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from '@webtv/app';

import { SentryClient } from './utils/sentry/SentryClient';

SentryClient.initialize();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(<App />);

import cmd, { BaseResponse } from '../../cmd';

interface RawResponse {
  tempUid: string;
}

interface Response {
  uid: string;
}

const responseMapper = (data: RawResponse): Response => {
  return {
    uid: data.tempUid
  };
};

export const createTempUid = (): Promise<BaseResponse<Response>> => {
  return cmd<RawResponse>({ cmd: 'createTempUid' })
    .then((response) => ({ ...response, data: responseMapper(response.data) }))
    .catch((error) => error);
};

export default createTempUid;

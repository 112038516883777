import * as Sentry from '@sentry/react';
import { CMD_VERSION } from '@webtv/constants';
import { Environment } from '@webtv/typings/shared';

export class SentryClient {
  static initialize(): void {
    Sentry.init({
      dsn: 'https://a22952a50aec49c689ed6ec6c54d9227@o1355868.ingest.sentry.io/4505402119421952',
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      debug: false,
      release: 'prod@' + CMD_VERSION,
      enabled: process.env.NODE_ENV !== Environment.Development
    });
  }
}

export interface SetCookieOptions {
  days?: number;
  path?: string;
}

interface SetCookie {
  (name: string, value: string, options?: SetCookieOptions): void;
}

export const setCookie: SetCookie = (name, value, options = {}) => {
  if (typeof window === 'undefined') return;

  const optionsWithDefaults = { days: 7, path: '/', ...options };

  const expires = new Date(Date.now() + optionsWithDefaults.days * 864e5);
  const encodedValue = encodeURIComponent(value);
  const path = optionsWithDefaults.path;

  document.cookie = `${name}=${encodedValue}; expires=${expires.toUTCString()}; path=${path};`;
};

export default setCookie;

import cmd, { BaseResponse } from '../../cmd';

export type LoginData = [] | string;

export const login = (email?: string, password?: string): Promise<BaseResponse<LoginData>> => {
  const params = { mitLoginMail: email, mitLoginPassword: password };

  return cmd<LoginData>({ cmd: 'login', method: 'post', params });
};

export default login;

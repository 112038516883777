import React from 'react';

export interface DialogContextProps {
  children: React.ReactNode | React.ReactNode[];
}

export interface DialogContextValues {
  opened: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

export const DialogContext = React.createContext({} as DialogContextValues);

export const DialogProvider = (props: DialogContextProps): JSX.Element => {
  const { children } = props;

  const [opened, setOpened] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <DialogContext.Provider value={{ opened, handleOpen, handleClose }}>
      {children}
    </DialogContext.Provider>
  );
};

export default DialogContext;

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Tracking from '@webtv/components/tracking';
import { BRAND } from '@webtv/constants';
import { AuthenticationProvider } from '@webtv/contexts/authentication-context';
import { CookieConsentProvider } from '@webtv/contexts/cookie-consent-context';
import { DialogProvider } from '@webtv/contexts/dialog-context';
import { FavoritesListsProvider } from '@webtv/contexts/favorites-lists-context';
import GlobalStyles from '@webtv/global-styles';
import useAuthentication from '@webtv/hooks/use-authentication/use-authentication';
import useCookieConsent from '@webtv/hooks/use-cookie-consent';
import { norlysTheme, stofaTheme } from '@webtv/themes';

import getLocalStorage from './utils/local-storage/get-local-storage';
import setLocalStorage from './utils/local-storage/set-local-storage';
import useBrand from './hooks/use-brand';

const AuthenticatedApp = React.lazy(
  () => import(/* webpackChunkName: "authenticated-app" */ '@webtv/authenticated-app')
);

const UnauthenticatedApp = React.lazy(
  () => import(/* webpackChunkName: "unauthenticated-app" */ '@webtv/unauthenticated-app')
);

const CookieBanner = React.lazy(
  () => import(/* webpackChunkName: "cookie-banner" */ '@webtv/components/cookie-banner')
);

const themeMapper = {
  STOFA: stofaTheme,
  NORLYS: norlysTheme
};

const AppProviders: React.FC = () => (
  <BrowserRouter>
    <ThemeProvider theme={themeMapper[BRAND]}>
      <DialogProvider>
        <FavoritesListsProvider>
          <AuthenticationProvider>
            <CookieConsentProvider>
              <GlobalStyles />
              <App />
            </CookieConsentProvider>
          </AuthenticationProvider>
        </FavoritesListsProvider>
      </DialogProvider>
    </ThemeProvider>
  </BrowserRouter>
);

export const App: React.FC = () => {
  const { cookiesAccepted } = useCookieConsent();

  const { tokenVerified, authenticated } = useAuthentication();

  const { isNorlys } = useBrand();

  if (!tokenVerified) return null;

  const urlParams = new URLSearchParams(window.location.search);
  const redirectParam = urlParams.get('redirect');

  // set feature flag for redirect
  if (redirectParam && redirectParam === 'false') {
    setLocalStorage('redirect', false);
  }

  // unset feature flag for redirect
  if (redirectParam && redirectParam === 'true') {
    setLocalStorage('redirect', true);
  }

  // get uid and token from local storage
  const uid = getLocalStorage('uid') || null;
  const token = getLocalStorage('cmd-token') || null;

  // set the base url for redirect
  const baseURL = 'https://norlysplay.dk';

  // if the user is logged in, redirect to norlysplay.dk with uid and token
  // else redirect to norlysplay.dk without uid and token
  if (!isNorlys && localStorage.getItem('redirect') !== 'false') {
    if (uid && token) {
      const composedURL = `${baseURL}?uid=${uid}&token=${token}`;

      window.location.href = composedURL;
    } else window.location.href = baseURL;
  }

  return (
    <React.Fragment>
      <React.Suspense fallback={null}>
        {authenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}

        {cookiesAccepted === undefined && <CookieBanner />}
      </React.Suspense>

      <Tracking />
    </React.Fragment>
  );
};

export default AppProviders;

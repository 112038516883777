import {
  BRAND,
  NORLYS_CUSTOMER_SUPPORT_NUMBER,
  STOFA_CUSTOMER_SUPPORT_NUMBER
} from '@webtv/constants';

import { Brand } from '@webtv/typings/shared';

const brandNameMapper = {
  NORLYS: 'Norlys Play',
  STOFA: 'Stofa WebTv'
};

export const useBrand = () => {
  return {
    brand: BRAND,
    brandName: brandNameMapper[BRAND],
    isStofa: BRAND === Brand.Stofa,
    isNorlys: BRAND === Brand.Norlys,
    customerSupportNumber:
      BRAND === Brand.Norlys ? NORLYS_CUSTOMER_SUPPORT_NUMBER : STOFA_CUSTOMER_SUPPORT_NUMBER
  };
};

export default useBrand;

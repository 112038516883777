import cmd, { BaseResponse } from '../../cmd';
import { FavoritesList } from '@webtv/typings/cmd';

interface RawFavoritList {
  name: string;
  sids: string[];
}

interface Response {
  tv: RawFavoritList[];
}

const responseMapper = (data: Response) => {
  return data.tv.map((favoritList, index) => {
    return {
      ...favoritList,
      id: index,
      sids: favoritList.sids.map((sid) => parseInt(sid))
    };
  });
};

export const getCustomerFavoritListsWithSids = (): Promise<BaseResponse<FavoritesList[]>> => {
  return cmd<Response>({ cmd: 'getCustomerFavoritListsWithSids' })
    .then((response) => ({ ...response, data: responseMapper(response.data) }))
    .catch((error) => error);
};

export default getCustomerFavoritListsWithSids;

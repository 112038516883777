import React from 'react';

import useHotjar from '@webtv/hooks/use-hotjar';
import useCookieConsent from '@webtv/hooks/use-cookie-consent';
import useGoogleAnalytics from '@webtv/hooks/use-google-analytics';

import { ENVIRONMENT, GA_ID } from '@webtv/constants';

import { Environment } from '@webtv/typings/shared';

const HOTJAR_CONFIG = {
  id: 1711597,
  version: 6
};

const TrackingScripts: React.FC = () => {
  const googleAnalytics = useGoogleAnalytics();
  const hotjar = useHotjar();

  if (ENVIRONMENT === Environment.Production) {
    hotjar.initialize(HOTJAR_CONFIG.id, HOTJAR_CONFIG.version);
    googleAnalytics.initialize(GA_ID);
  }

  return null;
};

export const Tracking: React.FC = () => {
  const { cookiesAccepted } = useCookieConsent();

  if (!cookiesAccepted) return null;

  return <TrackingScripts />;
};

export default Tracking;

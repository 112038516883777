import React from 'react';

import getLocalStorage from '@webtv/utils/local-storage/get-local-storage';
import setLocalStorage from '@webtv/utils/local-storage/set-local-storage';
import removeLocalStorage from '@webtv/utils/local-storage/remove-local-storage';

type UseLocalStorageNullableReturn<T> = [
  T | null,
  (value: T | ((value: T | null) => T)) => void,
  () => void
];
type UseLocalStorageReturn<T> = [T, (value: T | ((value: T | null) => T)) => void, () => void];

export function useLocalStorage<T>(key: string): UseLocalStorageNullableReturn<T>;
export function useLocalStorage<T>(key: string, defaultValue: T): UseLocalStorageReturn<T>;
export function useLocalStorage<T>(
  key: string,
  defaultValue: T | null = null
): UseLocalStorageNullableReturn<T> | UseLocalStorageReturn<T> {
  const [storedValue, setStoredValue] = React.useState<T | null>(() => {
    const value = getLocalStorage<T>(key);

    return value ? value : defaultValue;
  });

  const setValue = React.useCallback(
    (value: T | ((value: T | null) => T)) => {
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      setLocalStorage<T>(key, valueToStore);
    },
    [key, storedValue]
  );

  const removeValue = React.useCallback(() => {
    removeLocalStorage(key);

    setStoredValue(null);
  }, [key]);

  return [storedValue, setValue, removeValue];
}

export default useLocalStorage;

/** Brands */

export enum Brand {
  Norlys = 'NORLYS',
  Stofa = 'STOFA'
}

/** Environments */
export enum Environment {
  Development = 'development',
  Production = 'production',
  Staging = 'staging'
}

import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, button {
    font-family: ${(props) => props.theme.typography.fontFamily};
  }

  body {
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.background.default};
    font-weight: ${(props) => props.theme.typography.weight.light};
    font-size: 16px;
    letter-spacing: ${(props) => `${props.theme.typography.spacing.default}px`};
    min-height: 100vh;
  }

  div[id="root"] {
    min-height: 100vh;
  }

  button {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyles;

import React from 'react';

import CookieConsentContext, {
  CookieConsentContextValues
} from '@webtv/contexts/cookie-consent-context/cookie-consent-context';

export const useCookieConsent = (): CookieConsentContextValues => {
  const values = React.useContext(CookieConsentContext);

  return { ...values };
};

export default useCookieConsent;

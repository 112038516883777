import React from 'react';

import {
  trackEvent,
  initialize as initializeTracking,
  TrackEvent
} from '@webtv/utils/google-analytics/google-analytics';

type useGoogleAnalyticsReturn = {
  initialize: typeof initializeTracking;
  trackEvent: typeof trackEvent;
};

export const useGoogleAnalytics = (): useGoogleAnalyticsReturn => {
  const [initialized, setInitialized] = React.useState(false);

  const initialize = React.useCallback(
    (id: string) => {
      if (!initialized) {
        initializeTracking(id);
        setInitialized(true);
      }
    },
    [initialized]
  );

  const trackEventCallback = React.useCallback((params: TrackEvent) => {
    trackEvent(params);
  }, []);

  return { initialize, trackEvent: trackEventCallback };
};

export default useGoogleAnalytics;

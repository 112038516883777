import { trackEvent } from '@webtv/utils/google-analytics';

export enum TrackFavoritesListAction {
  Deleted = 'kanalliste slettet',
  Selected = 'ny kanalliste valgt',
  Create = 'opret ny liste',
  Created = 'kanalliste oprettet',
  Edit = 'rediger kanalliste',
  Edited = 'kanalliste redigeret'
}

export const trackFavoritesList = (action: TrackFavoritesListAction) => {
  trackEvent({ event: 'kanalliste', options: { action } });
};

export default trackFavoritesList;

import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import { detect } from 'detect-browser';

import { BRAND, CMD_URL, CMD_VERSION } from '@webtv/constants';
import { ContentProviderFilter, ContentType, UniverseSortingValue } from '@webtv/typings/cmd';
import { Brand } from '@webtv/typings/shared';
import getLocalStorage from '@webtv/utils/local-storage/get-local-storage';
import setLocalStorage from '@webtv/utils/local-storage/set-local-storage';

import createTempUid from './actions/create-temp-uid';

const getBrowser = (): string => {
  const browserName = detect()?.name;

  if (!browserName) return '';

  return browserName.charAt(0).toUpperCase() + browserName.slice(1);
};

const MANUFACTURER = 'webtv';
const COMPANY = BRAND;
const MODEL = getBrowser();

export interface LoginData {
  customerId: number;
  error?: string;
  homenetwork?: string;
  info?: string;
  installId: number;
  loginStatus: boolean;
  token?: string;
}

export interface BaseResponse<T> {
  data: T;
  error: string | number;
  info: string;
  status: boolean;
  loginData: LoginData;
}

export type LoginAction = {
  cmd: 'login';
  method: 'post';
  params?: { mitLoginMail?: string; mitLoginPassword?: string };
};

export type ResetPasswordAction = {
  cmd: 'requestPasswordChange';
  method?: 'get';
  params: { eMail: string };
};

export type GetStreamUrlAction = {
  cmd: 'getStreamUrl';
  params: { sid?: number; eventId?: number; mediaId?: number };
  method?: 'get';
};

export type CreateTempUidAction = {
  cmd: 'createTempUid';
  method?: 'get';
  params?: null;
};

export type GetNpvrStatusAction = {
  cmd: 'getNpvrStatus';
  method?: 'get';
  params?: null;
};

export type CloseStreamAction = {
  cmd: 'closeStream';
  method?: 'get';
  params: { sid?: number; eventId?: number; mediaId?: number; progress?: number };
};

export type KeepAliveAction = {
  cmd: 'keepAlive';
  method?: 'get';
  params: { sid?: number; mediaId?: number; eventId?: number; progress?: number };
};

export type GetPlayerSettingsAction = {
  cmd: 'getPlayerSettings';
  method?: 'get';
  params?: null;
};

export type GetEpgDataNowNextForChannelsAction = {
  cmd: 'getEpgDataNowNextForChannels';
  method?: 'get';
  params: {
    favoriteList: number;
    nextCount?: number;
  };
};

export type RecordEventAction = {
  cmd: 'recordEvent';
  method?: 'get';
  params: {
    sid: number;
    eventId: number;
  };
};

export type DeleteRecordingEventAction = {
  cmd: 'deleteRecordingEvent';
  method?: 'get';
  params: {
    sid: number;
    eventId: number;
  };
};

export type GetCustomerFavoritListsWithSidsAction = {
  cmd: 'getCustomerFavoritListsWithSids';
  method?: 'get';
  params?: null;
};

export type SaveCustomerFavListAction = {
  cmd: 'saveCustomerFavList';
  method?: 'get';
  params: {
    favListName: string;
    favoriteList: number;
    channelList: number[];
  };
};

export type DeleteCustomerFavListAction = {
  cmd: 'deleteCustomerFavList';
  method?: 'get';
  params: {
    favoriteList: number;
  };
};

export type GetContentUniversesAction = {
  cmd: 'getContentUniverses';
  method?: 'get';
  params?: null;
};

export type GetContentGenresAction = {
  cmd: 'getContentGenres';
  method?: 'get';
  params: {
    universeId: number;
    providers: ContentProviderFilter;
  };
};

export type GetContentListAction = {
  cmd: 'getContentList';
  method?: 'get';
  params: {
    universeId: number;
    mainGenreId: number;
    subGenreId?: number;
    providers: ContentProviderFilter;
    sortingType: UniverseSortingValue;
    erotic: boolean;
    start: number;
    itemLimit?: number;
  };
};

export type GetContentSpecialListAction = {
  cmd: 'getContentSpecialList';
  method?: 'get';
  params: {
    universeId: number;
    specialGenreId: number;
    providers: ContentProviderFilter;
    sortingType: UniverseSortingValue;
    erotic: boolean;
    start: number;
    itemLimit?: number;
  };
};

export type GetContentSortValuesAction = {
  cmd: 'getContentSortValues';
  method?: 'get';
  params: {
    universeId: number;
  };
};

export type GetSeriesInfoAction = {
  cmd: 'getSerieInfo';
  method?: 'get';
  params: {
    contentId: number;
    providers: ContentProviderFilter;
    universeId?: number;
  };
};

export type RemoveWatchedContentItemAction = {
  cmd: 'removeWatchedContentItem';
  method?: 'get';
  params: {
    contentId: number;
  };
};

export type GetSerieSeasonInfoAction = {
  cmd: 'getSerieSeasonInfo';
  method?: 'get';
  params: {
    contentId: number;
    universeId?: number;
    providers: ContentProviderFilter;
  };
};

export type SetFavoriteContentAction = {
  cmd: 'setFavoriteContent';
  method?: 'get';
  params: {
    contentId: number;
    setContentActive: boolean;
    contentType: ContentType;
  };
};

export type GetMovieInfoAction = {
  cmd: 'getMovieInfo';
  method?: 'get';
  params: {
    contentId: number;
    providers: ContentProviderFilter;
    universeId?: number;
  };
};

export type GetRelatedMoviesAction = {
  cmd: 'getMovieRelated';
  method?: 'get';
  params: {
    contentId: number;
    universeId?: number;
    providerCollectionId?: number;
    platformId: number;
    start: number;
    itemLimit: number;
  };
};

export type GetUniverseSearchAction = {
  cmd: 'getUniverseSearch';
  method?: 'get';
  params: {
    searchQ: string;
  };
};

export type GetEpgDataForChannelsAction = {
  cmd: 'getEpgDataForChannels';
  method?: 'get';
  params: {
    timestamp: number;
    favoriteList: number;
    hours: number;
  };
};

export type GetEpgGenresAction = {
  cmd: 'getEpgGenres';
  method?: 'get';
  params?: { [key: string]: any };
};

export type GetEpgDataForProgramAction = {
  cmd: 'getEpgDataForProgram';
  method?: 'get';
  params: { sid: number; eventId: number };
};

export type GetEpgDataForGenresAction = {
  cmd: 'getEpgDataForGenres';
  method?: 'get';
  params: {
    timeStampFrom: number;
    timeStampTo: number;
    mainGenreList: number[];
    sidList?: number[];
    favoriteList: number;
  };
};

export type GetEpgDataForChannelAction = {
  cmd: 'getEpgDataForChannel';
  method?: 'get';
  params: {
    favoriteList: number;
    hours: number;
    sid: number;
    timeStampFrom: number;
    timeStampTo: number;
  };
};

export type GetCustomerDataAction = {
  cmd: 'getCustomerData';
  method?: 'get';
  params?: { [key: string]: any };
};

export type GetProviderCollectionSerieInfoAction = {
  cmd: 'getProviderCollectionSerieInfo';
  method?: 'get';
  params: { contentId: number; providerId?: number; subProviderId?: string };
};

export type GetProviderCollectionSerieSeasonInfoAction = {
  cmd: 'getProviderCollectionSerieSeasonInfo';
  method?: 'get';
  params: { contentId: number; providerId?: number; subProviderId?: string };
};

export type GetProviderCollectionMovieInfoAction = {
  cmd: 'getProviderCollectionMovieInfo';
  method?: 'get';
  params: { contentId: number; providerCollectionId?: number; subProviderId?: string };
};

export type TokenInformationAction = {
  cmd: 'tokenInformation';
  method?: 'get';
  params: { token: string };
};

export type RequestPasswordChangeAction = {
  cmd: 'requestPasswordChange';
  method?: 'get';
  params: { eMail: string };
};

export type VerifyPasswordChangeAction = {
  cmd: 'verifyPasswordChange';
  method?: 'get';
  params: { password: string; token: string };
};

export type RequestActivateAccountAction = {
  cmd: 'requestActivateAccount';
  method?: 'get';
  params: { user: string };
};

export type ActivateAccountAction = {
  cmd: 'activateAccount';
  method?: 'get';
  params: { token: string; password: string };
};

export type RequestEmailChangeAction = {
  cmd: 'requestEmailChange';
  method?: 'get';
  params: { newEmail: string };
};

export type VerifyEmailChangeAction = {
  cmd: 'verifyEmailChange';
  method?: 'get';
  params: { password: string; token: string };
};

export type GetProviderCollectionInfoAction = {
  cmd: 'getProviderCollectionInfo';
  method?: 'get';
  params: { providerCollectionId: number };
};

export type GetProviderCollectionUniversesAction = {
  cmd: 'getProviderCollectionUniverses';
  method?: 'get';
  params: { providerCollectionId: number };
};

export type GetProviderCollectionContentGenresAction = {
  cmd: 'getProviderCollectionContentGenres';
  method?: 'get';
  params: { providerCollectionId: number; universeId: number };
};

export type GetProviderCollectionContentListAction = {
  cmd: 'getProviderCollectionContentList';
  method?: 'get';
  params: {
    universeId: number;
    sortingType: UniverseSortingValue;
    start: number;
    itemLimit?: number;
    mainGenreId: number;
    subGenreId?: number;
    providerCollectionId: number;
  };
};

export type GetCustomerDevicesAction = {
  cmd: 'getCustomerDevices';
  method?: 'get';
  params: {
    sid?: number;
    mediaId?: number;
  };
};

export type RemoveCustomerDevicesAction = {
  cmd: 'removeCustomerDevices';
  method?: 'get';
  params: {
    sid?: number;
    mediaId?: number;
    deviceUid: string[];
  };
};

export type getNielsenChannelMappingsAction = {
  cmd: 'getNielsenChannelMappings';
  method?: 'get';
  params?: undefined;
};

export type getAppFeaturesAction = {
  cmd: 'getAppFeatures';
  method?: 'get';
  params?: undefined;
};

export type rentVodMediaAction = {
  cmd: 'rentVodMedia';
  method?: 'get';
  params: {
    contentId: number;
    contentType: ContentType;
    email?: string;
    mediaId?: number;
    password?: string;
  };
};

export type GetUiElements = {
  cmd: 'getUiElements';
  method?: 'get';
  params: {
    favoriteList: number;
    area: string;
  };
};

export type GetUIElementContent = {
  cmd: 'getUiElementContent';
  method?: 'get';
  params: {
    uiElementId: number;
    favoriteList: number;
  };
};

export type CmdActions =
  | LoginAction
  | GetNpvrStatusAction
  | ResetPasswordAction
  | GetStreamUrlAction
  | CreateTempUidAction
  | CloseStreamAction
  | KeepAliveAction
  | GetPlayerSettingsAction
  | GetEpgDataNowNextForChannelsAction
  | RecordEventAction
  | DeleteRecordingEventAction
  | GetCustomerFavoritListsWithSidsAction
  | SaveCustomerFavListAction
  | DeleteCustomerFavListAction
  | GetContentUniversesAction
  | GetContentGenresAction
  | GetContentListAction
  | GetContentSpecialListAction
  | GetContentSortValuesAction
  | GetSeriesInfoAction
  | RemoveWatchedContentItemAction
  | GetSerieSeasonInfoAction
  | SetFavoriteContentAction
  | GetMovieInfoAction
  | GetRelatedMoviesAction
  | GetUniverseSearchAction
  | GetEpgDataForChannelsAction
  | GetEpgGenresAction
  | GetEpgDataForProgramAction
  | GetEpgDataForGenresAction
  | GetEpgDataForChannelAction
  | GetCustomerDataAction
  | GetProviderCollectionSerieInfoAction
  | GetProviderCollectionSerieSeasonInfoAction
  | GetProviderCollectionMovieInfoAction
  | RequestActivateAccountAction
  | ActivateAccountAction
  | RequestPasswordChangeAction
  | VerifyPasswordChangeAction
  | RequestEmailChangeAction
  | VerifyEmailChangeAction
  | GetProviderCollectionInfoAction
  | GetProviderCollectionUniversesAction
  | GetProviderCollectionContentGenresAction
  | GetProviderCollectionContentListAction
  | TokenInformationAction
  | GetCustomerDevicesAction
  | RemoveCustomerDevicesAction
  | getNielsenChannelMappingsAction
  | getAppFeaturesAction
  | rentVodMediaAction
  | GetUIElementContent
  | GetUiElements;

const jsonpRequest = (params: { [key: string]: any }) => {
  // Convert the query values to a format that the api will accept: eg. key: [1, 2, 3] => key[] = 1, key[] = 2, key[] = 3 //
  const stringParams = Object.keys(params)
    .reduce((acc, param) => {
      const value = params[param];

      const values = Array.isArray(value)
        ? value.map((innerValue) => [param + '[]', innerValue])
        : [[param, params[param]]];

      return [].concat(acc, values as []);
    }, [])
    .map((param) => encodeURIComponent(param[0]) + '=' + encodeURIComponent(param[1]))
    .join('&');

  const url = `${CMD_URL}?${stringParams}`;

  return axios({ url: url, method: 'get', adapter: jsonpAdapter });
};

const httpRequest = (params: { [key: string]: any }) => {
  // We need to convert the data to form data, before the api will accept it //
  const data = new FormData();
  Object.keys(params).forEach((key) => {
    data.append(key, params[key]);
  });

  return axios({ url: CMD_URL, method: 'post', data });
};

export async function cmd<T>(options: CmdActions): Promise<BaseResponse<T>> {
  const { method = 'get', params = {}, cmd } = options;

  let UID = getLocalStorage('uid');
  if (!UID && options.cmd !== 'createTempUid') {
    const {
      data: { uid }
    } = await createTempUid();
    UID = uid;
    setLocalStorage('uid', uid);
  }

  const baseParams = {
    manufacturer: MANUFACTURER,
    ver: CMD_VERSION,
    company: COMPANY === Brand.Norlys ? 'boxer' : 'stofa',
    model: MODEL,
    uid: UID,
    drmKey: UID,
    token: getLocalStorage('cmd-token')
  };

  const merged = { ...baseParams, ...(params || {}), cmd };
  const response = method === 'post' ? await httpRequest(merged) : await jsonpRequest(merged);

  if (cmd === 'login' && response.data && response.data.substring(0, 2) === ' (') {
    const loginJSON = JSON.parse(response.data.substring(2, response.data.length - 1));
    response.data = loginJSON;
  }

  if (!response.data || !response.data.data) {
    throw { info: response.data.info, code: parseInt(response.data.error) };
  }

  if (response.data.loginData && response.data.loginData.token) {
    const { token } = response.data.loginData;

    setLocalStorage('cmd-token', token);
  }

  return response.data;
}

export default cmd;

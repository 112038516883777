import { getCookie } from '@webtv/utils/cookie/get-cookie';

import { StreamType } from '@webtv/typings/player';

import {
  LoginAction,
  NextEpisodeStartAction,
  RecordingOrigin,
  TrackingCloseOrigin,
  TrackingPlayerActivity,
  TrackingVideoType
} from '@webtv/typings/tracking';

import { TrackFavoritesListAction } from './events/track-favorites-list';
import { TrackDeleteRecordingAction } from './events/track-delete-recording';
import { TrackCancelStartOverAction } from './events/track-cancel-start-over/track-cancel-start-over';

import {
  TrackOpenProgramSheetAccess,
  TrackOpenProgramSheetPresentation
} from './events/track-open-program-sheet/track-open-program-sheet';

const addGtagScript = (id: string) => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  script.async = true;

  document.head.insertBefore(script, document.head.childNodes[0]);
};

const addGtagFunction = (id: string) => {
  const script = document.createElement('script');
  script.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('set', {cookie_flags: 'SameSite=None;Secure'});
    gtag('config', '${id}');

    window.gtag = gtag
  `;

  document.head.insertBefore(script, document.head.childNodes[1]);
};

export const initialize = (id: string): void => {
  if (typeof window === 'undefined') return;

  addGtagScript(id);
  addGtagFunction(id);
};

type LoginEvent = {
  event: 'login_events';
  options: {
    action: LoginAction;
  };
};

type StartPlaybackEvent = {
  event: 'start_afspilning';
  options: {
    item_name?: string;
    item_category?: string;
    chosen_provider?: string;
    video_type: TrackingVideoType;
    genre?: string;
  };
};

type PlayerEvent = {
  event: 'aktivitet_under_afspilning';
  options: {
    action: TrackingPlayerActivity;
    video_type: TrackingVideoType;
    origin: 'fullscreen' | 'smallscreen';
  };
};

type StartOverEvent = {
  event: 'forlad_sff';
  options: { action: TrackCancelStartOverAction };
};

type FavoritesListEvent = {
  event: 'kanalliste';
  options: {
    action: TrackFavoritesListAction;
  };
};

type UniverseSortingEvent = {
  event: 'select_sorting';
  options: {
    action: 'når brugeren skifter sortering';
    selection: string;
    origin: string;
  };
};

type UniverseSelectGenreEvent = {
  event: 'select_genre';
  options: {
    action: 'når brugeren skifter genre';
    selection: string;
    origin: string;
  };
};

type SelectSearchSuggestionEvent = {
  event: 'valgt_soegeforslag';
  options: { item_name: string };
};

type ProfileIconEvent = {
  event: 'tryk_paa_login_ikon';
  options?: undefined;
};

type ChangeChannelEvent = {
  event: 'skift_kanal';
  options: { item_name: string };
};

type SearchFieldEvent = {
  event: 'soege_ikon';
  options?: undefined;
};

type SelectDateEvent = {
  event: 'vaelg_dato';
  options: {
    action: 'Når brugeren foretager valg af dato';
    day: number;
  };
};

type ClearTvGuideFiltersEvent = {
  event: 'nulstil_filter';
  options?: undefined;
};

type OpenTvGuideFilterEvent = {
  event: 'vis_filtermuligheder';
  options: {
    selection: 'dato' | 'kanal' | 'genre' | 'kanalliste';
  };
};

type SelectTvGuideGenreEvent = {
  event: 'vis_tvgenre';
  options: {
    selection: string;
  };
};

type SelectTvGuideChannelEvent = {
  event: 'vis_kanaloversigt';
  options: {
    chosen_provider: string;
  };
};

type CloseEvent = {
  event: 'fortryd';
  options: {
    origin: TrackingCloseOrigin;
  };
};

type MenuClickEvent = {
  event: 'tryk_paa_menupunkt';
  options: {
    selection:
      | 'hjem'
      | 'film'
      | 'serier'
      | 'udsendelser'
      | 'børn'
      | 'lejebutik'
      | 'tvguide'
      | 'livetv';
  };
};

type SearchResultsEvent = {
  event: 'soegnings_resultater';
  options: {
    search_term: string;
  };
};

type ErrorMessageEvent = {
  event: 'vis_fejlbesked';
  options: {
    error_type: string;
  };
};

type SelectUniverseGenreEvent = {
  event: 'select_genre';
  options: { selection: string };
};

type SelectUniverseSortingEvent = {
  event: 'select_sorting';
  options: { selection: string };
};

type SelectProviderUniverseEvent = {
  event: 'vis_providerunivers';
  options: {
    chosen_provider: string;
    content_presentation?: string;
    position?: number;
    access: string;
  };
};

type SelectShowAllLinkEvent = {
  event: 'tryk_paa_vis_alle';
  options: { collection_name: string; number_of_assets: number };
};

type SelectContentItemEvent = {
  event: 'longpress';
  options: {
    action: string;
    item_name?: string;
    collection_name: string;
    content_presentation?: string;
  };
};

type StartRecordingEvent = {
  event: 'planlaeg_enkeltoptagelse';
  options: {
    item_name?: string;
    item_category?: string;
    origin: RecordingOrigin;
  };
};

type CancelRecordingEvent = {
  event: 'annuller_enkeltoptagelse';
  options: {
    item_name?: string;
    item_category?: string;
    origin: RecordingOrigin;
  };
};

type OpenProgramSheetEvent = {
  event: 'vis_infosheet';
  options: {
    item_name: string;
    item_category?: string;
    content_presentation: TrackOpenProgramSheetPresentation;
    collection_name?: string;
    access: TrackOpenProgramSheetAccess;
    chosen_provider: string;
  };
};

type NoTvGuideResultsEvent = {
  event: 'ingen_resultater';
  options: {
    item_name: string;
  };
};

type PlayTrailerEvent = {
  event: 'trailerafspilning';
  options: { item_name: string };
};

type SelectSeriesSeasonEvent = {
  event: 'vaelg_season';
  options: { item_name: string; item_season?: number; total_seasons: number };
};

type ExpandSeriesEpisodeEvent = {
  event: 'fold_episode_ud';
  options: { item_name: string };
};

type ContinueWatchingEvent = {
  event: 'continue_watching_prompt';
  options: { item_name: string; item_category: string; action: string };
};

type SelectProviderVersionEvent = {
  event: 'providervalg';
  options: { item_name: string; item_category: string; chosen_provider: string };
};

type FavoriteContentEvent = {
  event: 'tilfoej_favorit';
  options: { item_name: string; item_category?: string; action: string; origin?: string };
};

type UnfavoriteContentEvent = {
  event: 'fjern_favorit';
  options: { item_name: string; item_category?: string; action: string; origin?: string };
};

type OpenContentSheetEvent = {
  event: 'vis_produktblad';
  options: {
    item_name: string;
    item_category?: string;
    content_presentation?: string;
    collection_name?: string;
    access?: string;
    position?: number;
  };
};

type MaxDevicesEvent = {
  event: 'enhed_maks_besked';
  options: {
    sid?: number;
  };
};

type RemoveDeviceEvent = {
  event: 'enhed_fjern';
  options?: undefined;
};

type EditDevicesEvent = {
  event: 'enhed_admin';
  options?: undefined;
};

type ClickSeriesButtonEvent = {
  event: 'gaa_til_serie';
  options: {
    item_name: string;
    item_category?: string;
    chosen_provider: string;
  };
};

type RentingStartEvent = {
  event: 'begynd_leje';
  options: {
    item_name: string;
    item_category: string;
  };
};

type RentingSuccessEvent = {
  event: 'leje_gennemfoert';
  options: {
    item_name: string;
    item_category: string;
    chosen_provider: string;
    kvalitet: string;
  };
};

type DeleteRecordingEvent = {
  event: 'slet_optagelse_player';
  options: {
    action: TrackDeleteRecordingAction;
  };
};

type NextEpisodeAppearEvent = {
  event: 'next_available';
  options: {
    item_name: string;
    video_type: StreamType;
  };
};

type NextEpisodeStartEvent = {
  event: 'next';
  options: {
    item_name: string;
    video_type: StreamType;
    action: NextEpisodeStartAction;
  };
};

export type TrackEvent =
  | LoginEvent
  | StartPlaybackEvent
  | PlayerEvent
  | StartOverEvent
  | SelectDateEvent
  | ProfileIconEvent
  | FavoritesListEvent
  | ChangeChannelEvent
  | UniverseSortingEvent
  | SelectSearchSuggestionEvent
  | OpenTvGuideFilterEvent
  | SelectShowAllLinkEvent
  | SelectSeriesSeasonEvent
  | SelectTvGuideGenreEvent
  | SelectContentItemEvent
  | ClearTvGuideFiltersEvent
  | UniverseSelectGenreEvent
  | SelectUniverseGenreEvent
  | SelectTvGuideChannelEvent
  | SearchFieldEvent
  | CloseEvent
  | MenuClickEvent
  | SearchResultsEvent
  | ErrorMessageEvent
  | SelectUniverseSortingEvent
  | SelectProviderUniverseEvent
  | SearchFieldEvent
  | StartRecordingEvent
  | CancelRecordingEvent
  | OpenProgramSheetEvent
  | NoTvGuideResultsEvent
  | PlayTrailerEvent
  | SelectSeriesSeasonEvent
  | ExpandSeriesEpisodeEvent
  | ContinueWatchingEvent
  | SelectProviderVersionEvent
  | FavoriteContentEvent
  | UnfavoriteContentEvent
  | OpenContentSheetEvent
  | RemoveDeviceEvent
  | EditDevicesEvent
  | MaxDevicesEvent
  | ClickSeriesButtonEvent
  | RentingStartEvent
  | RentingSuccessEvent
  | DeleteRecordingEvent
  | NextEpisodeAppearEvent
  | NextEpisodeStartEvent;

export const trackEvent = (params: TrackEvent): void => {
  if (getCookie('cookies-accepted') !== 'true' || typeof window.gtag === 'undefined') return;

  const { event, options = {} } = params;

  window.gtag('event', event, options);
};

import React from 'react';
import { initialize as initializeHotjar } from '@webtv/utils/hotjar';

type useHotjarReturn = {
  initialize: typeof initializeHotjar;
};

export const useHotjar = (): useHotjarReturn => {
  const [initialized, setInitialized] = React.useState(false);

  const initialize = React.useCallback(
    (id: number, version?: number) => {
      if (!initialized) {
        initializeHotjar(id, version);
        setInitialized(true);
      }
    },
    [initialized]
  );

  return { initialize };
};

export default useHotjar;

import React from 'react';

import useLocalStorage from '@webtv/hooks/use-local-storage';

import { BaseResponse } from '@webtv/utils/cmd/cmd';

import login from '@webtv/utils/cmd/actions/login';
import verifyToken from '@webtv/utils/cmd/actions/verify-token';
import { LoginData } from '@webtv/utils/cmd/actions/login/login';
import setLocalStorage from '@webtv/utils/local-storage/set-local-storage';
import useBrand from '@webtv/hooks/use-brand';

export interface AuthenticationContextProps {
  children: React.ReactNode | React.ReactNode[];
}

export interface AuthenticationContextValues {
  authenticated: boolean;
  tokenVerified: boolean;
  authenticate: (email: string, password: string) => Promise<BaseResponse<LoginData>>;
}

export const AuthenticationContext = React.createContext<AuthenticationContextValues>(
  {} as AuthenticationContextValues
);

export const AuthenticationConsumer = AuthenticationContext.Consumer;

export const AuthenticationProvider = (props: AuthenticationContextProps): JSX.Element => {
  const { children } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const tokenParam = urlParams.get('token') || null;
  const uidParam = urlParams.get('uid') || null;

  const { isNorlys } = useBrand();

  if (isNorlys && tokenParam && uidParam) {
    setLocalStorage('cmd-token', tokenParam);
    setLocalStorage('uid', uidParam);

    window.history.replaceState({}, document.title, window.location.pathname);
  }

  const [cmdToken] = useLocalStorage<string>('cmd-token');

  const [authenticated, setAuthenticated] = React.useState(false);

  const [tokenVerified, setTokenVerified] = React.useState(false);

  const handleVerifyToken = React.useCallback(async () => {
    try {
      await verifyToken();

      setAuthenticated(true);
    } catch (error) {
      localStorage.removeItem('cmd-token');
    } finally {
      setTokenVerified(true);
    }
  }, []);

  const authenticate = React.useCallback(async (email: string, password: string) => {
    const res = await login(email, password);

    setAuthenticated(true);

    return res;
  }, []);

  React.useEffect(() => {
    if (cmdToken) {
      handleVerifyToken();
    } else {
      setTokenVerified(true);
    }
  }, [cmdToken, handleVerifyToken]);

  return (
    <AuthenticationContext.Provider value={{ authenticated, authenticate, tokenVerified }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContext;

import { logError } from '@webtv/utils/cmd/actions/_utils';

export const removeLocalStorage = (key: string) => {
  if (typeof window === 'undefined') {
    console.warn(
      `Tried removing localStorage key “${key}” even though environment is not a client`
    );
    return;
  }

  try {
    localStorage.removeItem(key);
  } catch (error: any) {
    logError(`error removing localStorage key “${key}”`, error);
  }
};

export default removeLocalStorage;

import React from 'react';

import getCookie from '@webtv/utils/cookie/get-cookie';
import setCookie, { SetCookieOptions } from '@webtv/utils/cookie/set-cookie/set-cookie';

type UseCookieNullableReturn = [string | null, (value: string, options?: SetCookieOptions) => void];
type UseCookieReturn = [string, (value: string, options?: SetCookieOptions) => void];

export function useCookie(name: string): UseCookieNullableReturn;
export function useCookie(name: string, defaultValue: string): UseCookieReturn;
export function useCookie(
  name: string,
  defaultValue?: string
): UseCookieNullableReturn | UseCookieReturn {
  const [storedValue, setStoredValue] = React.useState<string | null>(() => {
    const value = getCookie(name);

    return value ? value : defaultValue || null;
  });

  const setValue = React.useCallback(
    (value: string, options?: SetCookieOptions) => {
      setStoredValue(value);

      setCookie(name, value, options);
    },
    [name]
  );

  return [storedValue, setValue];
}

export default useCookie;

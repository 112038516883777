import React from 'react';

import AuthenticationContext, {
  AuthenticationContextValues
} from '@webtv/contexts/authentication-context/authentication-context';

export const useAuthentication = (): AuthenticationContextValues => {
  const values = React.useContext(AuthenticationContext);

  return { ...values };
};

export default useAuthentication;
